import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { AuthService, CommonService } from '@rockwell-automation-inc/service';
import {
  combineLatest,
  filter,
  fromEvent,
  map,
  mergeMap,
  Observable,
  scan,
  skip,
  skipWhile,
  switchMap,
  take,
  takeWhile,
  tap,
  timer,
  withLatestFrom,
} from 'rxjs';
import { ControlPageFacade } from './+state/control-page';
import { PermHelperService, PermissionCondition, Permission } from './services/perm-helper.service';
import { LoggerFactoryToken } from '@services/logger.service';
import { Banner, NavBarTheme, NavItem } from '@rockwell-automation-inc/common-utils';
import { CommonConstants } from './common-constants';
import { AppBannerId, SessionManagementFacade } from '@ra-state';
import {
  IInnerButton,
  IInnerButtonClickedEvent,
  IBannerConfig,
  NotificationType,
} from '@ra-web-tech-ui-toolkit/components';
import { DatePipe } from '@angular/common';
import { environment } from '@environments/environment';

type NavItemConfig = {
  item: NavItem;
  condition: PermissionCondition<Permission>;
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('commonNavbar')
  commonNavbar!: ElementRef<HTMLInputElement>;
  logoTypeTheme: NavBarTheme = NavBarTheme.DARK;
  returnTo: string | undefined;
  countDownTimer$: Observable<number>;
  countDownTime: number;
  landingImageURL: string = `${environment.appConfiguration.adminPortalURL}/assets/images/adminportal-landing-150dpi.png`;
  currentBannerType: Banner;
  bannerItems: Banner[] = [
    {
      show: false,
      message: '',
      config: {
        showDefaultIcon: true,
        showCloseIcon: false,
        useNotificationBackground: false,
        notificationType: NotificationType.Warning,
      },
      buttons: [
        {
          label: 'Continue',
        },
      ],
      raIcon: '',
      id: AppBannerId.SessionTimeout,
    },
  ];
  bannerMessage: string = 'Welcome to Admin Portal';
  bannerIcon = 'ra-icon-ide-lg-system';
  bannerButtons: IInnerButton[] = [
    {
      label: 'button label',
      data: 'data',
    },
  ];
  bannerConfig: IBannerConfig = {
    showDefaultIcon: true,
    showCloseIcon: true,
    useNotificationBackground: true,
    notificationType: NotificationType.Warning,
  };

  userInfo: User | undefined;
  userLoggedIn$ = this.authService.isAuthenticated$.pipe(
    skipWhile((it) => it.valueOf() === false),
    switchMap(() => this.authService.user$),
    tap((userProfile) => {
      this.commonService.setUserId(userProfile.userId);
      this.controlPageFacade.setUserLoggedIn(userProfile);
    }),
  );

  navConfig: NavItemConfig[] = [
    {
      item: {
        action: 'self',
        raIconClass: 'ra-icon-ide-md-key-edit',
        displayName: 'Trials',
        id: 'trials',
        route: 'trials',
      },
      condition: {
        permissions: [Permission.ListTrialCampaign],
      },
    },
    {
      item: {
        action: 'self',
        raIconClass: 'ra-icon-ide-md-stacked-safety-module-top',
        displayName: 'Organizations',
        id: 'tenants',
        route: 'organizations',
      },
      condition: {
        permissions: [Permission.ListTenant],
      },
    },
    {
      item: {
        action: 'self',
        raIconClass: 'ra-icon-ide-sm-user-group',
        displayName: 'Users',
        id: 'users',
        route: 'users',
      },
      condition: {
        permissions: [Permission.ListUser],
      },
    },
    {
      item: {
        action: 'self',
        raIconClass: 'ra-icon-ide-lg-aplications',
        displayName: 'Entitlements',
        id: 'entitlements',
        route: 'entitlement',
      },
      condition: {
        permissions: [Permission.ListEntitlement],
      },
    },
  ];

  navItems$ = combineLatest(
    this.navConfig.map((it, i) => {
      return this.permissionHelper.checkPermission$(it.condition).pipe(
        map((result) => {
          return result ? [this.navConfig[i].item] : [];
        }),
      );
    }),
  ).pipe(
    map(
      (children) =>
        [
          {
            displayName: 'COMMON ADMIN MANAGER',
            id: 'common-admin-manager',
            isHeading: true,
            children: children.flat(),
          },
        ] as NavItem[],
    ),
    // tap(it => console.log("Navitems: ", it))
  );

  isLoading$ = this.controlPageFacade.isLoading$;

  signInClicked(): void {
    this.authService.loginWithoutPopup(this.returnTo);
  }
  logoutClicked(): void {
    this.authService.logout();
  }

  goToHome(): void {
    this.router.navigate(['/']);
  }

  getReturnTo(params: Params): string {
    const searchParams = new URLSearchParams();
    for (const key of Object.keys(params)) {
      if (key !== 'returnTo') {
        searchParams.set(key, params[key]);
      }
    }
    const searchParamsStr = searchParams.toString();
    let returnTo = `${params['returnTo']}`;
    if (searchParamsStr) {
      returnTo = returnTo.concat(`?${searchParams.toString()}`);
    }
    return returnTo;
  }

  userInfo$ = this.authService.factoryTalkUser$.pipe(
    tap((user: User) => {
      this.userInfo = user;
    }),
  );

  userId$ = this.authService.user$.pipe(
    take(1),
    filter((user) => user?.userId !== undefined),
    map((user) => user.userId),
    tap((userId) => {
      this.commonService.setUserId(userId);
    }),
  );

  get logoTypeThemeClass(): string {
    return this.logoTypeTheme === NavBarTheme.DARK ? 'ra-logo-ft-hub-dark' : 'ra-logo-ft-hub-light';
  }

  setLogoTheme(theme: NavBarTheme): void {
    this.logoTypeTheme = theme;
  }

  menuItemClick(item: any): void {
    this.router.navigate([item.route]);
  }

  sessionTimedOut$ = this.sessionManagementFacade.userActive$.pipe(
    skip(1),
    tap((userActive) => {
      if (userActive === true) {
        this.extendUserSession();
      } else if (userActive === false) {
        this.showBanner(AppBannerId.SessionTimeout);
        this.countDownTimer$ = this.startCountDownTimer$();
      }
    }),
    mergeMap(() => fromEvent(document, 'click').pipe(take(1))),
    tap(() => {
      this.extendUserSession();
      this.hideBanner(AppBannerId.SessionTimeout);
      this.sessionManagementFacade.setUserActivityRecorded(true);
    }),
  );

  setCountDownTime(countDownTime: number): void {
    this.countDownTime = countDownTime;
  }

  startCountDownTimer$(): Observable<number> {
    const countDownTime = this.countDownTime || CommonConstants.sessionBannerMaxDurationInSeconds;
    return timer(0, 1000).pipe(
      scan((acc) => acc - 1, countDownTime),
      takeWhile((x) => x >= 0 && this.isBannerActive(AppBannerId.SessionTimeout)),
      withLatestFrom(this.sessionManagementFacade.userActivityRecordedAt$),
      tap(([timer, userActivityRecordedAt]) => {
        const elapsedMillis = Date.now() - userActivityRecordedAt;
        if (timer === 0 || elapsedMillis > CommonConstants.allowedElapsedTimeWithoutActivityMillis) {
          this.endUserSession();
        }
      }),
      map(([timer, _]) => timer * 1000),
      tap((timer) => {
        const bannerMessage = `Session timeout in ${this.datePipe.transform(
          timer,
          'mm:ss',
          'UTC',
        )}s due to inactivity. Click anywhere to continue your session`;
        const timeOutBanner = this.bannerItems.find((item) => item.id === AppBannerId.SessionTimeout);
        if (timeOutBanner) {
          timeOutBanner.message = bannerMessage;
        }
      }),
    );
  }

  endUserSession(): void {
    this.authService.logout();
  }

  extendUserSession(): void {
    this.authService.checkAuth0Session();
    this.sessionManagementFacade.setUserActivityRecorded(true);
  }

  showBanner(appBannerId: AppBannerId): void {
    this.bannerItems.forEach((item) => {
      if (item.id === appBannerId) {
        item.show = true;
      }
    });
  }

  isBannerActive(appBannerId: AppBannerId): boolean {
    return Boolean(this.bannerItems.find((item) => item.id === appBannerId)?.show);
  }

  hideBanner(appBannerId: AppBannerId): void {
    this.bannerItems.forEach((item) => {
      if (item.id === appBannerId) {
        item.show = false;
      }
    });
  }

  bannerButtonClicked($event: IInnerButtonClickedEvent): void {
    if ($event.button.data) {
      this.router.navigate([$event.button.data.redirectUrl]);
    }
  }

  logger = inject(LoggerFactoryToken)(AppComponent);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private commonService: CommonService,
    private controlPageFacade: ControlPageFacade,
    private permissionHelper: PermHelperService<Permission>,
    private datePipe: DatePipe,
    private sessionManagementFacade: SessionManagementFacade,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(filter((params) => params['returnTo'])).subscribe((params) => {
      this.returnTo = this.getReturnTo(params);
    });
  }
}
